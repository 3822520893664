<template>
  <div id="dropzone" class="flex-col flex-center" :class="$style.dropzone">
    <span class="w-100 center-align no-select" :class="$style['dropzone-text']">Dateien hier ablegen</span>
    <span class="w-100 center-align no-select" :class="$style['dropzone-separator']">oder</span>

    <div class="row">
      <VueUploadComponent
        class="btn"
        name="file"
        drop="#dropzone"
        :post-action="uploadUrl"
        :data="uploadData"
        :multiple="true"
        :extensions="uploadConfiguration.allowedExtensions"
        :accept="uploadConfiguration.allowedMimeTypes"
        :size="uploadConfiguration.maxSize"
        @input-filter="inputFilter"
        v-model="uploadQueue"
        ref="upload">
          Auswählen
      </VueUploadComponent>
    </div>

    <span class="w-100 center-align no-select" :class="$style['dropzone-separator']" style="margin-top: -0.5rem">oder</span>

    <div class="row">
      <button @click="onPasteButton" class="btn">Aus Zwischenablage einfügen</button>
    </div>
  </div>

  <TransitionGroup v-if="hasUploads" id="uploadQueue" name="list" tag="ul" class="collection mb-0">
    <FileUploadSelectedFile
      v-for="file of uploadQueue" :key="file.id"
      @title-change="file.data.title = $event"
      @uploaded="addUploadedFile(file)"
      @delete="$refs.upload.remove(file)"
      :file-name="file.name"
      :file-size="file.size"
      :title="file.data.title"
      :preview="file.thumbnail"
      :progress="parseFloat(file.progress)"
      :error="file.error"
      :response="file.response"/>
  </TransitionGroup>
  <a v-if="hasUploads" @click="$refs.upload.clear" :class="$style['list-action']" href="#!" class="right">
    <label for="uploadQueue" class="clickable">Alle entfernen</label>
  </a>

  <div v-if="uploadQueue?.length" class="flex-row flex-hcenter mt-2" :class="$style.buttons">
    <button @click="upload" :disabled="$refs.upload?.active" class="waves-effect waves-light btn">
      <!--<i class="material-icons left">file_upload</i>-->Upload starten
    </button>

    <Switch v-if="companyName" v-model="uploadToCompany" :textOn="'für ' + companyName" textOff="nur für mich" label="Sichtbarkeit:" class="ml-1"/>
  </div>

  <UploadedFileSortableList v-model="uploadedFiles"/>

  <div class="bottom-padding"></div>
</template>

<script>
import VueUploadComponent from "vue-upload-component";
import Switch from "@/components/Switch.vue";
import FileUploadSelectedFile from "@/components/FileUploadSelectedFile.vue";
import UploadedFileSortableList from "@/components/UploadedFileSortableList.vue";
import { actions, events, mutations, routes, API_URL } from "@/constants.js";

export default {
  name: "FileUpload",
  data() {
    return {
      uploadConfiguration: {
        allowedExtensions: "jpg,jpeg,png,gif,webp,bmp,tiff,avif,pdf,rtf,doc,xls,ppt,docx,xlsx,pptx,odt,ods,odp",
        // eslint-disable-next-line max-len
        allowedMimeTypes: "image/jpg,image/jpeg,image/png,image/gif,image/webp,image/bmp,image/tiff,image/avif,application/pdf,application/rtf,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,pplication/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text",
        maxSize: 20 * 1024 * 1024
      },
      uploadQueue: [],
      downloadPageFile: null,
      uploadToCompany: false
    };
  },
  computed: {
    hasUploads() {
      return this.uploadQueue?.length;
    },
    uploadUrl() {
      return API_URL + routes.UPLOAD;
    },
    companyName() {
      return this.$store.state.user?.company?.name;
    },
    uploadData() {
      if (!this.uploadToCompany) return {};
      return { uploadToCompany: this.uploadToCompany };
    },
    uploadedFiles: {
      get() {
        return this.$store.state.sessionFiles;
      },
      set(value) {
        this.$store.commit(mutations.SET_SESSION_FILES, value);
      }
    }
  },
  methods: {
    async onPasteButton() {
      try {
        await navigator.permissions.query({ name: "clipboard-read" });
      }
      catch (e) {
        return this.$toast.warning("Diese Funktion wird von Ihrem Browser leider nicht unterstützt. Bitte verwenden Sie stattdessen Strg-V.");
      }

      try {
        const data = await navigator.clipboard?.read();
        if (!data) throw new Error("Failed to read clipboard");

        // Only PNGs are supported by browsers at the moment
        const TYPE = "image/png";
        const files = await Promise.all(data.filter((d) => d.types.includes(TYPE)).map((d) => d.getType(TYPE)));
        this.$refs.upload.add(files.map(this.blobToFileObject));
      }
      catch (e) {
        return this.$toast.warning("Inhalt der Zwischenablage konnte nicht gelesen werden. Bitte verwenden Sie stattdessen Strg-V.");
      }
    },
    blobToFileObject(blob) {
      return {
        file: blob,
        size: blob.size,
        type: blob.type,
        name: "Bild.png"
      };
    },
    pasteFromClipboard(e) {
      if (e.target.tagName == "INPUT") return;
      if (!e.clipboardData?.files) return;

      const files = e.clipboardData.files;

      // eslint-disable-next-line max-len
      if (!files.length) return this.$toast.warning("Es konnten keine validen Daten in der Zwischenablage gefunden werden.<br>Möglicherweise unterstützt Ihr Browser diese Funktion nicht.<br>Bitte verwenden Sie stattdessen eine der alternativen Uploadmöglichkeiten.", { duration: 5000 });

      this.$refs.upload.add(Array.from(files));
      e.preventDefault();
    },
    inputFilter(newFile, oldFile) {
      if (newFile && newFile.error === "" && newFile.file && newFile.file !== oldFile?.file) {
        // Folder dropped: remove directory path from file name
        const pos = newFile.name.lastIndexOf("/");
        if (pos > -1) newFile.name = newFile.name.substr(pos + 1);

        // Title
        newFile.data.title = this.getTitleSuggestion(newFile.name);

        // Create a blob field
        newFile.blob = "";
        if (window.URL) newFile.blob = URL.createObjectURL(newFile.file);

        // Thumbnails
        newFile.thumbnail = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") newFile.thumbnail = newFile.blob;
      }
    },
    addUploadedFile(file) {
      this.$store.dispatch(actions.ADD_FILE, file.response);
      this.$refs.upload.remove(file);
    },
    upload() {
      this.$refs.upload.active = true;
    },
    getTitleSuggestion(fileName) {
      const dotPosition = fileName.lastIndexOf(".");
      if (dotPosition < 1) return fileName;

      return fileName.substr(0, dotPosition);
    }
  },
  mounted() {
    window.addEventListener(events.PASTE, this.pasteFromClipboard);
  },
  unmounted() {
    window.removeEventListener(events.PASTE, this.pasteFromClipboard);
  },
  components: {
    VueUploadComponent,
    Switch,
    FileUploadSelectedFile,
    UploadedFileSortableList
  }
};
</script>

<style module lang="scss">
  $dropzone-color: #ccc;

  .dropzone {
    height: 18rem;
    border: 4px dashed $dropzone-color;
    color: darken($dropzone-color, 10%);
    margin-bottom: 2rem;

    .dropzone-text {
      margin-top: 1rem;
      font-size: 2rem;
    }

    .dropzone-separator {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .drop-overlay {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: 0.8;
    text-align: center;
    background: #606060;

    h3 {
      color: white;
    }
  }

  .list-action {
    margin-right: 4px;
  }
</style>
