<template>
  <Card>
    <div class="row center">
      <h5>Firmenkonto erstellen</h5>
      <label>
        Hier können Sie ein Unternehmenskonto anlegen.<br>
        Im Anschluss haben Sie die Möglichkeit, diesem Nutzer zuzuweisen.
      </label>
    </div>

    <form @submit.prevent="register" class="w-100 mt-1">
      <div class="row">
        <InputField required v-model="name" id="name" label="Name" autocomplete="off" class="my-0" icon="business" />
      </div>

      <div class="input-field col s12">
        <button :disabled="busy" type="submit" class="btn waves-effect waves-light col s12">Account erstellen</button>
      </div>
    </form>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import InputField from "@/components/InputField.vue";
import { actions, pages } from "@/constants";

export default {
  name: "CompanyCreate",
  data() {
    return {
      name: ""
    };
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    }
  },
  methods: {
    async register() {
      const result = await this.$store.dispatch(actions.CREATE_COMPANY, this.name);
      if (result !== null) this.$router.push(pages.USER_MANAGE + "/" + result.id);
    }
  },
  components: {
    Card,
    InputField
  }
};
</script>

<style module type="scss">

</style>
