<template>
  <div class="valign-wrapper" :class="inputClasses">
    <input readonly @focus="$event.target.select()" :value="content" type="text" ref="inputField">
  </div>

  <IconButton @click="copyToClipboard" icon="content_copy" :tooltipOn="tooltip" class="ml-05" />
</template>

<script>
import { events } from "@/constants.js";
import IconButton from "@/components/IconButton.vue";

export default {
  name: "CopyInputField",
  props: {
    content: {
      type: String,
      required: true
    },
    inputClasses: {
      type: String,
      default: "flex-grow hide-on-small-only"
    },
    tooltip: {
      type: String,
      default: "Direktlink kopieren"
    }
  },
  methods: {
    copyToClipboard() {
      this.$refs.inputField.focus();
      document.execCommand(events.COPY);
    }
  },
  components: {
    IconButton
  }
};
</script>

<style module lang="scss">

</style>
