<template>
  <div class="row">
    <div class="col s6 offset-s3 z-depth-6 card">
      <div class="card-content">
        <h5 class="center">Login</h5>
        <form @submit.prevent="login">
          <div class="row">
            <InputField required v-model="loginData.email" id="email" label="Email" class="my-0" icon="mail_outline" />
          </div>

          <div class="row">
            <InputField required v-model="loginData.password" id="password" label="Passwort" type="password" class="my-0" icon="lock_outline" />
          </div>

          <div class="row">
            <div class="input-field col s12">
              <button :disabled="busy" type="submit" class="btn waves-effect waves-light col s12">Login</button>
            </div>
          </div>
        </form>
      </div>

      <div class="card-action flex-row">
        <router-link to="register" :class="$style.action">Registrieren</router-link>
        <div class="flex-grow"></div>
        <router-link to="passwordreset" :class="$style.action">Passwort vergessen?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/InputField.vue";
import { actions, pages } from "@/constants.js";

export default {
  name: "Login",
  data() {
    return {
      loginData: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
		busy() {
			return this.$store.state.busy;
		}
  },
  methods: {
    async login() {
      const result = await this.$store.dispatch(actions.LOGIN, this.loginData);
      if (result !== null) this.$router.push(pages.HOME);
    }
  },
  components: {
    InputField
  }
};
</script>

<style module type="scss">
  :global(.card-action)>a.action {
    margin-right: 0!important;
    color: #616161!important;
  }

  :global(.card-action)>a.action:hover {
    color: #212121!important;
  }
</style>
