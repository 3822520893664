<template>
  <Card>
    <h5>Sie werden ausgeloggt...</h5>
    <p>
      Bitte haben Sie einen Moment Geduld.<br>
      Sie werden automatisch weitergeleitet.
    </p>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import { actions, pages } from "@/constants.js";

export default {
  name: "Logout",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  async mounted() {
    if (this.isLoggedIn) await this.$store.dispatch(actions.LOGOUT);
    this.$router.replace(pages.HOME);
  },
  components: {
    Card
  }
};
</script>

<style module type="scss">

</style>
