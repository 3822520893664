import { actions, http, mutations, pages, routes, API_URL } from "@/constants.js";
import { convertFileResponse } from "@/util.js";
import router from "@/router";
import { useToast } from "vue-toast-notification";

const toast = useToast({ position: "top" });

export default {
  async [actions.GET_USER](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.GET_USER, onError: () => null });
    if (result) context.commit(mutations.SET_USER, result.user);

    return Boolean(result);
  },
  async [actions.GET_FILES](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.UPLOADS.USER });
    if (result) context.commit(mutations.SET_UPLOADED_FILES, result.files.map((f) => convertFileResponse(f)));
  },
  async [actions.GET_ANONYMOUS_FILES](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.UPLOADS.ANONYMOUS });
    if (result) context.commit(mutations.SET_ANONYMOUS_FILES, result.files.map((f) => convertFileResponse(f)));
    return result;
  },
  async [actions.GET_COMPANY_FILES](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.COMPANY.FILES });
    if (result) context.commit(mutations.SET_COMPANY_FILES, result.files.map((f) => convertFileResponse(f)));
  },
  async [actions.ADD_FILE](context, file) {
    file = convertFileResponse(file);
    await context.commit(mutations.ADD_SESSION_FILE, file);

    const action = file.belongsToCompany? actions.ADD_COMPANY_FILE: actions.ADD_UPLOADED_FILE;
    await context.dispatch(action, file);
  },
  [actions.ADD_UPLOADED_FILE](context, file) {
    if (context.state.uploadedFiles === null) return;

    context.commit(mutations.ADD_UPLOADED_FILE, file);
  },
  [actions.ADD_COMPANY_FILE](context, file) {
    if (context.state.companyFiles === null) return;

    context.commit(mutations.ADD_COMPANY_FILE, file);
  },
  async [actions.DELETE_FILE](context, { id, administrationToken }) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.FILE.get(id),
      method: http.DELETE,
      body: { administrationToken }
    });

    if (result !== null) context.commit(mutations.DELETE_FILE, id);
  },
  async [actions.MERGE_FILE](context, data) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.MERGE,
      method: http.POST,
      body: data
    });

    if (!result) return null;
    await context.dispatch(actions.ADD_FILE, result);
  },
  async [actions.LOGIN](context, data) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.LOGIN,
      method: http.POST,
      body: data,
      onError: (response) => {
        if (response.response.status == 403) {
          router.push({
            path: pages.REGISTER_CONFIRM,
            query: { email: data.email }
          });
        }
        else {
          toast.error("Login fehlgeschlagen: " + response.error.message);
        }
      }
    });

    if (!result) return null;

    context.commit(mutations.SET_USER, result.user);
    toast.success("Login erfolgreich");
  },
  async [actions.LOGOUT](context) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.LOGOUT,
      method: http.POST,
      onError: () => toast.error("Logout fehlgeschlagen")
    });

    if (!result) return null;

    context.commit(mutations.RESET_STATE);
    toast.success("Erfolgreich abgemeldet");
  },
  async [actions.REGISTER](context, data) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.ACCOUNT.REGISTER.REGISTER,
      method: http.POST,
      body: data
    });

    return result || null;
  },
  async [actions.CREATE_COMPANY](context, companyName) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.COMPANY.CREATE,
      method: http.PUT,
      body: { name: companyName }
    });

    if (!result) return null;
    toast.success("Firmenkonto erfolgreich erstellt");
    context.commit(mutations.ADD_COMPANY, result);
    return result;
  },
  async [actions.GET_COMPANY_USERS](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.COMPANY.USERS, redirectOnError: true });

    if (!result) return null;
    context.commit(mutations.SET_COMPANY_USERS, result.users);
  },
  [actions.GET_COMPANY_INVITATIONS](context, id) {
    return context.dispatch(actions.API_REQUEST, {
      route: routes.COMPANY.invitationLinkList(id),
      redirectOnError: true
    });
  },
  async [actions.GET_COMPANIES](context) {
    const result = await context.dispatch(actions.API_REQUEST, { route: routes.COMPANY.LIST, redirectOnError: true });

    if (!result) return null;
    context.commit(mutations.SET_COMPANIES, result.companies);
  },
  async [actions.EDIT_USER](context, user) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.USER.edit(user.id),
      method: http.PUT,
      body: user
    });

    if (result !== null) toast.success("Nutzerdaten erfolgreich geändert");
    return result;
  },
  async [actions.CREATE_INVITATION](context, companyId) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.COMPANY.invitationLinkCreate(companyId),
      method: http.POST
    });

    return result;
  },
  async [actions.CREATE_SHORTLINK](context, { id, token }) {
    const result = await context.dispatch(actions.API_REQUEST, {
      route: routes.SHORTLINK.create(id),
      method: http.POST,
      body: {
        administrationToken: token
      }
    });

    return result;
  },
  async [actions.API_REQUEST](context, payload) {
    let returnValue = null;
    context.commit(mutations.SET_BUSY, true);

    try {
      const options = {
        method: payload.method || http.GET,
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        }
      };

      if (payload.body !== undefined) options.body = JSON.stringify(payload.body);

      const response = await fetch(API_URL + payload.route, options);
      const json = await response.json();

      if (json.state === false) {
        if (payload.onError) {
          payload.onError({
            response: response,
            error: json.error
          });
        }
        else {
          toast.error((payload.errorMessage ?? "") + json.error.message);
          if (payload.redirectOnError) {
            switch (json.error.code) {
              case "not-logged-in":
                router.push(pages.LOGIN);
                break;
              case "insufficient-permissions":
                router.push(pages.HOME);
                break;
            }
          }
        }
      }
      else {
        returnValue = json;
      }
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toast.error("Es konnte keine Verbindung zum Server hergestellt werden. Bitte versuchen Sie es später erneut.");
    }

    context.commit(mutations.SET_BUSY, false);
    return returnValue;
  }
};
